<template>
  <div class="examine">
    <b-container fluid>
      <b-row>
        <b-col lg="12" v-if="isReveal === '1'">
          <iq-card>
            <!-- <template v-slot:headerTitle>
              <h4 class="card-title">{{title}}</h4>
            </template> -->
            <template v-slot:body>
              <p class="all-crumbs">文件审查 > {{info.title}}</p>
              <el-form ref="form" :model="form" label-width="90px">
                <el-form-item label="文件名称" prop="fileName">
                  <el-input
                    v-model="form.fileName"
                    maxlength="100"
                    placeholder="请输入文件名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="发起人姓名" prop="name">
                  <el-input
                    v-model="form.name"
                    maxlength="100"
                    placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="审查状态">
                  <el-select v-model="form.type" filterable placeholder="请选择状态">
                    <el-option
                      v-for="item in typeList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="order-top-btn" @click="search">搜 索</div>
              </el-form>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="child">
      <div class="possess" v-if="isReveal === '1'">
        <div class="table-main">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :header-cell-style="{'text-align': 'center'}"
            :cell-style="{'text-align': 'center'}"
            tooltip-effect="dark"
          >
            <el-table-column prop="contract_id" label="待审核文件">
              <template slot-scope="scope" >
                <div class="col-file" @click="preview(scope.row,'view',1)" style="cursor: pointer;">
                  <img src="@/assets/images/zxsc/ldht-1.png" class="certified-Img" />
                  <span class="fileSpan" :title="scope.row.title" >{{scope.row.title}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="contract_id" label="已交付文件">
              <template slot-scope="scope" >
                <div class="col-file" @click="preview(scope.row,'view',3)" style="cursor: pointer;" v-if="scope.row.status == 2">
                  <img src="@/assets/images/zxsc/ldht-1.png" class="certified-Img" />
                  <span class="fileSpan" :title="scope.row.title" >{{scope.row.title}}</span>
                </div>
                <div class="col-file" v-else>
                  <img src="@/assets/images/zxsc/ldht-2.png" class="certified-Img" />
                  <span class="fileSpan" :title="scope.row.title" >{{scope.row.title}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="发起人姓名" show-overflow-tooltip/>
            <el-table-column prop="add_time" label="发起时间" show-overflow-tooltip/>
            <el-table-column prop="bookTime" label="预约时间" show-overflow-tooltip/>
            <el-table-column prop="consult" label="标签"
            :filters='[{text:"七天",value:"七天"},{text:"加急",value:"加急"}]'
            :filter-method="statusSelect" />
            <el-table-column prop="status_info" label="状态" show-overflow-tooltip/>
            <!-- <el-table-column label="状态">
               <template slot-scope="scope">
               <span
               :style="{color:scope.row.status==2?'#FD8F00':'#1CB531'}"
               >{{scope.row.status_info}}</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="caozuo" label="协作" >
              <template slot-scope="{row}">
              <div class="operate" v-if="row.isCompleted != 1">
                <div class="handle handle2" @click="subscribe(row.id)"><img src="@/assets/images/zxsc/ldht-3.png" v-if="row.isBookTime != 1"/>{{row.isBookTime == 1?'已预约':'预约'}}</div>
                <!-- <div class="handle" @click="telephone(row)"><img src="@/assets/contract/icon_vidao.png"/>视频</div> -->
                <div class="handle handle3" @click="preview(row,'edit',2)"><img src="@/assets/images/zxsc/ldht-4.png"/>编辑</div>
              </div>
              <div class="operate" v-else>
                <div class="handle handle2" style="background:#e2e2e2;color:#b1b1b1;border:none;cursor: no-drop;"><img src="@/assets/images/zxsc/ldht-3.png"/>预约</div>
                <!-- <div class="handle" style="background:#e2e2e2;color:#b1b1b1;border:none;cursor: no-drop;"><img src="@/assets/contract/icon_vidao.png"/>视频</div> -->
                <div class="handle handle3" style="background:#e2e2e2;color:#b1b1b1;border:none;cursor: no-drop;"><img src="@/assets/images/zxsc/ldht-4.png"/>编辑</div>
              </div>
              </template>
            </el-table-column>
            <el-table-column  label="是否交付" width="200%">
              <template slot-scope="scope">
              <div class="delivery" @click="submit(scope.row)" v-if="scope.row.status !=2">确认交付</div>
              <div class="delivery-active" v-else>已交付</div>
              </template>
            </el-table-column>
          </el-table>
          <div class="page">
            <el-pagination
              background
              @size-change="handleSizeChange"
              :page-sizes="[6, 12, 24, 48]"
              :page-size="listPageSize"
              layout="sizes, prev, pager, next"
              :total="listTotal"
              :current-page.sync="listCurrentPage"
            />
          </div>
        </div>
      </div>
      <div v-if="isReveal === '3'" class="pdf editor" ref="edit">
        <div class="edit-btn">
          <el-button @click="goBack"
            v-if="isFullScreen == false"
            > 返回</el-button
          >
          <el-button class="large" @click="fullWidth" v-if="isFullScreen == false">大屏显示</el-button>
          <el-button class="large" @click="goNormal" v-if="isFullScreen == true">退出大屏</el-button>
        </div>
         <div style="padding: 0 10px; height: 100%" v-if="isPdf">
          <iframe
            :src="pdfsite"
            frameborder="0"
            width="100%"
            height="100%"
            ref="mapFrame"
            scrolling="auto"
            class="mapFrame"
            id="mapFrame"
          ></iframe>
        </div>
        <el-dialog
          title="编辑文件"
          :visible.sync="isShowEdit"
          :center="true"
          :show-close="false"
          :destroy-on-close="true"
          :modal="false"
          top="0"
          width="100%"
          class="edit-dialog"
          v-else
        >
          <div class="holder">
            <div id="placeholder"></div>
          </div>
        </el-dialog>
      </div>
      <el-dialog
        :visible.sync="dialogVisible"
        width="375px"
        class="yg-tc"
        top="35vh"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <ul v-if='!wxCode'>
          <li class="t-img">
            <!-- <img src="@/assets/home/rz_tixiang.png" /> -->
          </li>
          <li class="t-title">审查文件将发送至您的手机，需要您签字确认，</li>
          <li class="t-text" style="color:#666;">请移到手机进行确认！</li>
        </ul>
        <ul v-else>
         <li class="t-title">审查文件已发送至您的手机,根据短信内容进行确认</li>
          <li class="t-text" style="color:#666;margin-bottom:10px;">您也可扫描以下二维码进行签字确认</li>
          <li class="t-img" >
            <img :src="wxCode" style='width:120px;height:120px'/>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirm" v-if='!wxCode'>{{loding?'发送中':'确 定'}}</el-button>
          <el-button @click="cancel">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- 预约时间 -->
    <appointment v-if="isAppointment"  />
    <remote-js :src="CX_Api" />
  </div>
</template>

<script>
import { core } from '../../../config/pluginInit'
import { getLawyerExamList, confirmExamine } from "@/FackApi/api/examine";
import { viewInitEditor } from "@/Utils/viewonlineEdit";
import { initEditor } from "@/Utils/onlineEdit";
import appointment from "./appointment.vue";
export default {
  name: "examine",
  components: {
    appointment,
    "remote-js": {
      render(createElement) {
        return createElement("script", {
          attrs: { type: "text/javascript", src: this.src },
        });
      },
      props: {
        src: { type: String, required: true },
      },
    },
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      title: '',
      isAppointment:false,//预约协作弹窗
      isFullScreen:false,//是否大屏显示
      dialogVisible:false,//交付弹窗
      isShowEdit:false,//显示文档
      form: {
        fileName: "", //文件名称
        name: "", //姓名
        type: 0, //审查状态
      },
      typeList:[
        {name:'全部',value:0},
        {name:'审查中',value:1},
        {name:'已完成',value:2},
      ],
      isReveal: "1",
      listTotal: 0,
      listCurrentPage: 1,
      listPageSize: 6,
      tableData: [],
      isSeal: false,
      transactionId: "",
      iframeWin: "",
      imgList: [],
      isLookDetail: false,
      x: "",
      y: "",
      p: "",
      fileUrl: "",
      pdfPath: "",
      filterObj: "",
      lawyerId:'',//预约时间律师ID
      contractid:'',//确认交付ID
      exa_id:'',//预约时间ID
      routerType:'',//路由类型
      wxCode:'',//微信二维码
      loding:false,//发送确定
      isPdf:false,//是否显示PDF
      pdfsite:'',//PDF地址
    };
  },
  watch: {
    listCurrentPage(oldV, newV) {
      this.exchangeCurrentPage();
    },
  },
  created() {
    this.title = this.info.title;
    this.routerType = this.info.type;
    this.getCheckSealData();
  },
  mounted() {
    core.index()
  },
  methods: {
    //获取列表数据
    getCheckSealData() {
        let {fileName,name,type} = this.form;
        let str = `?pageIndex=${this.listCurrentPage}&pageSize=${this.listPageSize}&status=${type}&tp=${this.routerType}`;
        if (fileName) {
          str += `&keys=${fileName}`;
        }
        if (name) {
          str += `&name=${name}`;
        }
        // console.log(str);
        getLawyerExamList(str).then(res=>{
          // console.log(res);
          if(res.code == 0){
            this.listTotal = res.count;
            this.tableData = res.data;
          }
        })
    },
     //提交确认
    submit(row){
      // console.log(row);
      confirmExamine(row.id).then(res=>{
        // console.log(res);
        if (res.code == 0) {}
      })
      // this.lawyerId = row.id
      // this.contractid = row.contract_id
      // this.dialogVisible = true
    },
    //关闭确认
    cancel(){
      this.dialogVisible = false;
      setTimeout(()=>{
        this.wxCode=''
      },500)
    },
    //确认交付
    confirm(){
      if(this.loding) return false
      this.loding = true
      let {lawyerId,contractid} =this
      let str = {
        "contractid": contractid,
        "lawexamid": lawyerId,
        "type":this.routerType
      }
      this.$api.sendForLawyerSign(str).then(res=>{
        if(res.code == 200){
          this.wxCode = res.qRcode
          this.loding = false
        }else{
          this.loding = false
        }
      }).catch(err=>{
        this.loding=false
      })
    },
    //跳转视频
    telephone(row) {
       if(row.bookVideoUrl){
        window.open(row.bookVideoUrl, '_blank');
      }else{
        this.contractId = row.id
        // let routeUrl = this.$router.resolve({path: '/video'});
        window.open('https://meeting.ai-hrcompliance.com', '_blank');
      }
    },
    //翻页
    exchangeCurrentPage() {
      this.getCheckSealData();
    },
    //设置几条一页
    handleSizeChange(val) {
      this.listPageSize = val;
      this.getCheckSealData();
    },
    //搜索
    hunt() {
      this.getCheckSealData();
    },
    //文件搜索
    search(){
        let {fileName,name,type} = this.form;
        let str = `?pageIndex=${this.listCurrentPage}&pageSize=${this.listPageSize}&status=${type}&tp=${this.routerType}`;
        if (fileName) {
          str += `&keys=${fileName}`;
        }
        if (name) {
          str += `&name=${name}`;
        }
        getLawyerExamList(str).then(res=>{
          if(res.code == 0){
            this.listTotal = res.count;
            this.tableData = res.data;
          }
        })
    },
    //获取可预约时间段
    subscribe(vid) {
      this.isAppointment = true
      this.lawyerId = vid
      this.exa_id = vid
    },
    //查看详情
    preview(row,type,num) {
      let path
      if(num == 1){
        path = row.path
      }else if(num == 2){
        path = row.finalPath
      }else if(num == 3){
        this.isPdf = true
        this.$nextTick(() =>{
          this.pdfsite = '../../../../pdf-view/pdfobject.html?url='+row.finalPathPdf
        })
      }
      this.isReveal = "3";
      this.edit(row.lawyers_name,row.serailNo,path,row.title,type)
    },
    //在线编辑
    edit(name, code,url, title,type) {
      console.log(name, code,url, title,type);
      this.isShowEdit = true;
      if(type == 'view'){
        viewInitEditor
         setTimeout(() => {
          viewInitEditor(
            name,
            url,
            true,
            true,
            true,
            true,
            code,
            type,
            "desktop|mobile",
            {
              comment: true,
              download: false,
              edit: true,
              print: false,
              review: true,
            },
            title,
            this.COOKIE_TOKEN
          );
        }, 500);
      }else{
        setTimeout(() => {
          initEditor(
            name,
            url,
            true,
            true,
            true,
            true,
            code,
            "edit",
            "desktop|mobile",
            {
              comment: true,
              download: false,
              edit: true,
              print: false,
              review: true,
            },
            title,
            this.COOKIE_TOKEN
          );
        }, 500);
      }
    },
    //编辑返回
    goBack(){
      this.isReveal = '1'
    },
    //全宽显示
    fullWidth(){
        this.isFullScreen = true
        this.$refs.edit.setAttribute('class','fullScreen')
    },
    //返回正常屏幕
    goNormal(){
      this.isFullScreen = false
      this.$refs.edit.setAttribute('class','pdf')
    },
    //重新发送（签署链接）
    sendLink() {
      this.isSigned = false;
      this.$api.repeatSend({ contractIds: this.ids }).then((res) => {
        this.ids = [];
        this.getCheckSealData();
        if (res.success === false) {
          this.$message({ message: res.msg, type: "error", offset: 400 });
        } else {
          this.$message({ message: res.msg, type: "success", offset: 400 });
        }
      });
    },
    //状态改变
    statusSelect(e,val) {
      return e == val.consult
    },
  },
};
</script>

<style lang="less" scoped>
.examine {
  .container-fluid {
    margin-top: 8px;
  }
  /deep/ .el-form {
    display: flex;
    align-items: center;
    .el-form-item {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      .el-form-item__label {
        // width: 100px !important;
        font-size: 14px;
        color: #333;
        margin-bottom: 0;
        text-align: left;
        white-space: nowrap;
      }
      .el-form-item__content {
        margin: 0 10px 0 0 !important;
        .el-input {
          .el-input__inner {
            width: 200px;
            height: 40px;
            font-size: 14px;
            background: #eaf5ff;
            border-radius: 10px;
            border: none;
          }
        }
      }
    }
    .order-top-btn {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border-radius: 25px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      cursor: pointer;
    }
  }
  .possess {
    .table-main {
      overflow-x: auto;
      /deep/ .el-table {
        height: initial !important;
        box-shadow: 0px 0px 10px 0px rgba(152,172,190,0.2);
        border-radius: 18px;
        .el-table__header-wrapper {
          .el-table__header {
            .has-gutter {
              .el-table__cell {
                background: #F2F4FF;
                .cell {
                  font-size: 14px;
                  font-weight: bold;
                  color: #6474C7;
                }
              }
            }
          }
        }
        .el-table__body-wrapper {
          min-height: 450px;
          .el-table__body {
            .el-table__row {
              td {
                padding: 0;
                .cell {
                  font-size: 14px;
                  color: #333;
                  padding: 8px 15px;
                  .col-file {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img {
                      width: 80px;
                      height: 90px;
                      margin-bottom: 10px;
                    }
                    span {
                      // max-width: 150px;
                      width: 100%;
                      font-size: 14px;
                      color: #333;
                      white-space:nowrap;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                  .operate {
                    .handle {
                      margin: 10px 0;
                      cursor:pointer;
                      img {
                        width: 18px;
                        height: 20px;
                        margin-right: 10px;
                      }
                    }
                    .handle2 {
                      color: #6474C7;
                    }
                    .handle3 {
                      color: #45CFA0;
                    }
                  }
                  .delivery {
                    width: 120px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 14px;
                    color: #6474C7;
                    border-radius: 24px;
                    border: 1px solid #6474C7;
                    cursor:pointer;
                  }
                }
              }
            }
          }
        }
      }
      .page {
        margin: 30px 0 10px;
        .el-pagination {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .pdf {
    margin-bottom: 30px;
    .edit-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      button {
        width: 90px;
        border-radius: 4px;
        height: 30px;
        padding: 0;
      }
    }
    /deep/ .el-dialog__wrapper{
      position: inherit !important;
      width: 100%;
      height: 800px !important;
      overflow: inherit !important;
      .el-dialog--center {
        height: 100% !important;
        .el-dialog__body {
          height: 94% !important;
          .holder {
            height: 100% !important;
          }
        }
      }
    }
  }
}
.fullScreen{
  position:fixed;
  width:100vw;
  height:100vh;
  left:0;
  top:0;
  z-index:999999;
  background:#fff;
  padding-top:20px;
  .edit-btn {
    margin-left: 20px;
    button {
      width: 90px;
      border-radius: 4px;
      height: 30px;
      padding: 0;
    }
  }
  ::v-deep .el-dialog__header{
    display: none;
  }
  ::v-deep .edit-dialog{
    margin-top: 60px;
    height: 100% !important;
  }
  ::v-deep .el-dialog--center{
    height: 100% !important;
  }
  ::v-deep .el-dialog__body{
    padding: 0 20px 80px 20px !important;
    height: 98% !important;
    .holder {
      height: 100%;
    }
  }
  .large{
    top: 10px;
    right: 20px;
  }
}
.justify-content-between {
  min-height: 0 !important;
}
.all-crumbs {
  margin-bottom: 20px !important;
}
</style>
