<template>
  <div class="dialog-newcreate">
    <el-dialog :visible.sync="$parent.isAppointment" :width="popWidth" :top="popTop">
      <div class="title">预约协作时间</div>
      
      <div class="top" v-if="!isMsgVideo">
         
        <div class="left">
          <div class="title1">律师审查预约30分钟协作时间</div>
          <div class="text">
            <img src="@/assets/images/zxsc/ldht-4.png">
            <span>30分钟</span>
          </div>
          <div class="text">
            <!-- <img src="@/assets/bottom_3/icon_vidao.png"> -->
            <!-- <span>确认后提供网络会议详细信息</span> -->
          </div>
          <div v-if="makeTime" class="text2">
            <!-- <div style="font-size: 15px;margin-bottom:10px;" v-if="initUrl">点击链接进入视频会议:</div>
            <div class="msg-click" style="margin-bottom:20px;" @click="clickVideo(initUrl)" v-if="initUrl">{{initUrl}}</div> -->
            <span style="font-size: 15px;font-weight: bold;">已预约:{{makeTime}}</span>
            <div class="btn" @click="iscancel = true" v-if="!iscancel">取消预约</div>
            <div  v-else>
              <el-input
                type="textarea"
                :rows="5"
                maxlength="75"
                show-word-limit
                resize='none'
                placeholder="请输入取消原因"
                v-model="textarea">
              </el-input>
              <div>
              <el-tag class="tabs-cion" @click="clickTags('预约时间冲突。')">预约时间冲突。</el-tag>
              <el-tag type="success" class="tabs-cion" @click="clickTags('时间选择错了。')">时间选择错了。</el-tag>
              <el-tag type="danger" class="tabs-cion" @click="clickTags('其他原因')">其他原因</el-tag>
              </div>
              
              <div class="cancel-btn ds" @click="delMake">确认</div>
              <div class="cancel-btn1 ds" @click="iscancel = false">取消</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div style="display: inline-block;" :style="{width:isRight?'75%':'100%'}">
            <xcalendar />
          </div>
          <ul class="timeList" style="width:25%;" v-if="isRight">
              <!-- <img src="@/assets/manual/fMsg.png" v-else> -->
              <!-- <img src="@/assets/login/login3.png" v-else> -->
            <!-- <li class="video" @click="carryVideo == 1?carryVideo= 2:carryVideo= 1">
              <span class="line" v-if="carryVideo == 1"></span>
              <span style="vertical-align: top;">同时预约视频协作。</span>
            </li> -->
            <li class="xing">{{week}}</li>
            <li class="timeitme-header" v-for="v,i in timeList" :key='i' @click="subscribe(i)">
              <span class="timeitme" v-if='!v.isClick'>{{v.hour}}</span>
              <div class="timeitme-split" v-else>
                <span class="div1">{{v.hour}}</span>
                <span class="div2" @click="confirm(v.hour)">{{isSend?'预约中...':'确 认'}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="top" v-else style="padding: 40px 50px 15px 50px">
        <div class="msg-img">
          <!-- <img src="@/assets/home/icon_sucsess.png"> -->
          <div>{{msgTitle}}</div>
        </div>
        <ul class="msg-div">
          <li>{{msgTheme}}</li>
          <li>会议时间：{{msgTime}}</li>
          <li style="margin-top:20px" v-if="msgUrl">点击链接入会，或添加至会议列表：</li>
          <li class="msg-click" @click="clickVideo(msgUrl)" v-if="msgUrl">{{msgUrl}}</li>
          <li style="margin-top:20px" v-if="msgUrl">会议ID：{{msgId}}</li>
          <li v-if="msgUrl">用户名：{{msgName}}</li>
        </ul>
      </div>
      <div class="footer">
        <div class="btn" @click="$parent.isAppointment=false">关 闭</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import xcalendar from './custorm'
import { getBookHour, addBookHour, delBookHour } from "@/FackApi/api/examine";
export default {
  components:{xcalendar},
  data() {
    return {
      popWidth:'1100px',//弹窗默认宽度
      popTop:'5vh',//弹窗距离头部
      msgTitle:'',//成功标题  视频或者预约
      msgTheme:'',//成功的主题
      msgTime:'',//会议时间
      msgUrl:'',//会议的url
      msgId:'',//会议ID
      msgName:'',//会议用户名
      initUrl:'',//初始化视频入口
      isMsgVideo:false,//是否显示视频预约成功弹窗
      carryVideo:1,//是否携带视频
      isRight:false,//是否点击日历
      timeList:[],//日程时间列表
      week:'',//星期几
      date:'',//日期时间
      isMsg:false,//是否预约成功
      makeTime:'',//已预约时间
      textarea:'',//文本内容
      iscancel:false,//是否显示取消预约
      isSend:false,//预约加载
    }
  },
  mounted(){
    this.getData()
  },
  methods: {
    //获取预约信息
    getData(){
      getBookHour(this.$parent.exa_id).then(res=>{
        if(res.code == 0){
          this.makeTime = res.data
          this.initUrl = res.url
        }
      })
    },
    //预约
    subscribe(i){
      this.timeList.forEach((v,index)=>{
        if(index== i){
          v.isClick = true
        }else{
          v.isClick = false
        }
      })
    },
    //取消粤语
    delMake(){
      if(!this.textarea) return this.$message.error('取消原因不能为空')
      delBookHour({exa_id:this.$parent.exa_id,remarks:this.textarea }).then(res=>{
        if(res.code == 0){
          this.makeTime  =''
          this.isRight = false
          this.$parent.getCheckSealData()
          this.$message.success('取消成功')
        }
      })
    },
    //点击跳转视频
    clickVideo(url){
      window.open(url,'_blank')
    },
    //点击标签
    clickTags(val){
      this.textarea = val
    },
    //点击确定
    confirm(hour) {
      if(this.isSend) return false
      this.isSend = true
      let str = {
        date:this.date,
        time:hour,
        type:this.carryVideo,
        exa_id:this.$parent.exa_id,
      }
      addBookHour(str).then(res=>{
        if(res.code == 0){
          this.msgTitle = res.title
          this.msgTheme = res.body
          this.msgTime = res.context
          this.msgUrl = res.vurl
          this.msgName = res.uname
          this.msgId = res.classid
          this.popTop = '15vh'
          this.popWidth = '635px'
          this.isMsgVideo = true
          this.isSend = false
          this.$parent.getCheckSealData()
        }else{
          this.isSend = false
        }
      }).catch(err=>{
        this.isSend = false
      })
    },
  },
};
</script>

<style scoped lang='scss'>
.dialog-newcreate {
  ::v-deep .el-button{
    border-radius: 2px !important;
  }
  ::v-deep .el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #eeeeee;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: #333333;
      border-radius: 10px 10px 0 0;
    }
    .msg-click{
      color: #2480ef;
      text-decoration:underline;
      cursor: pointer;
      // overflow: hidden;
      // text-overflow:ellipsis;
      // white-space: nowrap;
    }
    .top {
      position: relative;
      padding: 70px 60px 25px 60px;
      .msg-img{
        text-align: center;
        line-height: 30px;
      }
      .msg-div{
        margin-top: 30px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        line-height: 24px;
        color: #666666;
        
      }
      .left{
        width: 25%;
        display: inline-block;
        height: 500px;
        border-right: 1px solid #e2e2e2;
        .title1{
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          margin-bottom: 20px;
          color: #333333;
        }
        .text{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 30px;
          color: #333333;
          img{
            margin-right:10px;
            vertical-align: unset;
          }
        }
        .text2{
          color: #333;
          margin-top: 8px;
          .btn{
            width: 70px;
            height: 30px;
            color: #fff;
            line-height: 30px;
            text-align: center;
            background: #0061D5;
            cursor: pointer;
            margin-top: 16px;
            border-radius:8px ;
            padding: 0 4px;
          }
          .el-textarea{
            width: calc(100% - 20px);
            margin-top:20px;
          }
          .cancel-btn{
            width: 60px;
            height: 30px;
            color: #fff;
            line-height: 30px;
            text-align: center;
            background: #0061D5;
            cursor: pointer;
            margin-top: 6px;
            border-radius:8px ;
            padding: 0 4px;
            margin-right: 10px;
          }
          .cancel-btn1{
            width: 60px;
            height: 32px;
            color: #333;
            line-height: 32px;
            text-align: center;
            background: #fff;
            border: 1px solid #e2e2e2;
            cursor: pointer;
            margin-top: 6px;
            border-radius:8px ;
            padding: 0 4px;
            margin-right: 10px;
          }
          .tabs-cion{
            margin-top: 10px;
            cursor: pointer;
            margin-right: 5px;
          }
        }
      }
      .right{
        width: 75%;
        display: inline-block;
        vertical-align: top;
        padding: 0 2%;
        
         
        .timeList{
          display: inline-block;
          vertical-align: text-top;
          position: relative;
          animation:parentLeft 1s 1;
          -webkit-animation:parentLeft 1s 1;
          @-webkit-keyframes parentLeft {
              from{
                opacity: 0;
                left: 50px;
              }to{
                opacity: 1;
                left: 0;
              }
            }
            @keyframes parentLeft {
              from{
                opacity: 0;
                left: 50px;
              }to{
                opacity: 1;
                left: 0;
              }
            } 
          .xing{
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            margin-bottom: 20px;
          }
          .timeitme{
            display: inline-block;
            width: 100%;
            height: 46px;
            line-height: 46px;
            text-align: center;
            background: #FFFFFF;
            border: 1px solid #A7CFFE;
            border-radius: 4px;
            font-size: 18px;
            font-family: Arial;
            font-weight: bold;
            color: #0061D5;
            cursor: pointer;
            margin-bottom: 10px;
          }
          
          .timeitme-split{
            margin-bottom: 10px;
            animation:fadeInLeft 1s 1;
            -webkit-animation:fadeInLeft 1s 1;
            position:relative;
            @-webkit-keyframes fadeInLeft {
              from{
                opacity: 0;
                left: 100px;
              }to{
                opacity: 1;
                left: 0;
              }
            }
            @keyframes fadeInLeft {
              from{
                opacity: 0;
                left: 100px;
              }to{
                opacity: 1;
                left: 0;
              }
            } 
            left: 0;
            .div1{
              display: inline-block;
              width: 47%;
              height: 46px;
              line-height: 46px;
              background: #666666;
              border-radius: 4px;
              margin-right: 6%;
              color: #fff;
              font-size: 17px;
              text-align: center;
              vertical-align: bottom;
              cursor: pointer;
            }
            .div2{
              display: inline-block;
              width: 47%;
              height: 46px;
              line-height: 46px;
              color: #fff;
              font-size: 17px;
              background: #0061D5;
              text-align: center;
              border-radius: 4px;
              cursor: pointer;
            }
          }
           
         
        }
      }
    }
    .footer {
      text-align: center;
      padding: 30px 0;
      .btn {
        display: inline-block;
        width: 190px;
        height: 46px;
        line-height: 32px;
        text-align: center;
        border: 1px solid #999999;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #333333;
        margin-left: 20px;
        border-radius: 23px;
        cursor: pointer;
      }
      .btn:hover {
        border-color: #0867da;
        color: #0867da;
      }
      .btn1 {
        background: linear-gradient(180deg, #2480ef 0%, #0867da 100%);
        color: #ffffff;
        margin-left: 0;
        border: none;
      }
      .btn1:hover {
        background: linear-gradient(180deg, #2480ef 100%, #0867da 0%);
        color: #fff;
      }
    }
  }
}
.video{
  // position: absolute;
  // right: 0;
  // top: 10px;
  // margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  color: #0061D5;
  cursor: pointer;
  .line{
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border:1px solid #999;
    cursor: pointer;
    vertical-align: sub;
    margin:0 10px  20px 0;
  }
  img{
    display: inline-block;
    margin:0 10px  20px 0;
    width: 16px;
    height: 16px;
    vertical-align: sub;
  }
}

</style>