<template>
  <div class="x-custorm_calendar">
    <el-calendar v-model="value">
      <template 
      slot="dateCell"
      slot-scope="{data}">
       <span @click="cliakcdata($event,data)">{{data.day.split('-')[2]}}</span>
       <!-- <span style="background:#cadef5;">{{data.day.split('-')[2]}}</span> -->
      </template>
       
    </el-calendar>
  </div>
</template>

<script>
import { getHRBookHours } from "@/FackApi/api/examine";
export default {
 data() {
    return {
      value: '',
    }
  },
  mounted(){
    this.changeStyle()
  },
  methods:{
    //点击了
    cliakcdata(e,val){
      let nowTime = this.$common.getTime()
      if(val.day == nowTime){
        this.getdata(val)
      }else{
        let clickTime = new Date(val.day)
        let clickTimestamp =   (new Date(clickTime)).getTime();
        let nowTimestamp =  (new Date(nowTime)).getTime(); 
        if(clickTimestamp < nowTimestamp) return this.$message.error('预约时间不能小于当天')
        this.getdata(val)
      }
        // setTimeout(()=>{
        //   let el = document.getElementsByClassName('is-selected')[0]
        //     el.firstChild.firstChild.style.backgroundColor = '#fff'
        //     el.firstChild.firstChild.style.color = '#606266'
        //   },50)
         
          // setTimeout(()=>{
          // let el = document.getElementsByClassName('is-selected')[0]
          //   el.firstChild.firstChild.style.backgroundColor = '#0061D5'
          //   el.firstChild.firstChild.style.color = '#fff'
          // },50)
    },
    //获取数据
    getdata(val){
      let exa_id = this.$parent.$parent.$parent.lawyerId
      // let date = this.$common.shiftGMT(val)
      let date = val.day
      // date = date.split(' ')[0]
      let str = `?date=${date}&exa_id=${exa_id}`;
      getHRBookHours(str).then(res=>{
        if(res.code == 0){
          if(res.data.bookHours.length >0){
            let data = res.data.bookHours
            data.forEach(v=>{
              v.isClick = false
            })
            this.$parent.$parent.timeList = data
            this.$parent.$parent.week = res.data.week
            this.$parent.$parent.date = date
            this.$parent.$parent.isRight = true
          }else{
            this.$message.error('今日律师暂未开放预约或预约已满,请选择其他日期')
            this.$parent.$parent.timeList = []
            this.$parent.$parent.isRight =false
          }
        }else{
          this.$parent.$parent.isRight =false
        }
      })
    },
    //样式修改
    changeStyle(){
      let tdHeight = document.getElementsByClassName('current')[0]
      let tdDayHeight = document.getElementsByClassName('el-calendar-day')
      for(var i =0;i<tdDayHeight.length;i++){
        tdDayHeight[i].style.height = '60px'
        tdDayHeight[i].style.lineHeight = '60px'
      }
      tdHeight.style.height = '60px'
    },
  },
  watch:{
    value(val){
     
    }
  }
}
</script>

<style scoped lang='scss'>
.x-custorm_calendar{
  ::v-deep .el-calendar__header{
    border:none;
  }
  ::v-deep .current{
    border: none !important;
    span{
      display: inline-block;
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 50%;
      vertical-align: -webkit-baseline-middle;
    }
    .el-calendar-day{
      padding: 0;
    }
  }
  // ::v-deep .current ~ .is-today{
  //   pointer-events: none;
  // }
  //  ::v-deep .is-today{
  //   border: none !important;
  // }
  ::v-deep .next{
    border: none !important;
  }
  ::v-deep .prev{
    border: none !important;
  }
  ::v-deep .el-calendar-day{
    text-align: center;
  }
  ::v-deep .el-calendar-day:hover{
      background: #fff;
    }
  ::v-deep .is-selected {
    position: relative;
    background: #fff;
    .el-calendar-day{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      padding: 0px !important;
      text-align: center;
    }
    span{
      background: #0061D5;
      color:#fff;
      display: inline-block;
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 50%;
      vertical-align: -webkit-baseline-middle;
    }
  }
  ::v-deep .current:hover{
    background: #fff;
  }
}
</style>