// 引入request
import requests from '../../Utils/request'

// 获取风险点列表
// export const getRiskList = (params) => requests({
//   url: 'document/file/risk/list?id=1',
//   method: 'get',
//   params
// })
//获取任务列表
export const getLawyerExamList = (params) => requests({
  url: `lawyerConsult/list${params}`,
  method: 'get',
  // data
})
//获取成功预约时间
export const getBookHour = (params) => requests({
  url: `lawyerConsult/bookhour/${params}`,
  method: 'get',
  // data
})
//获取可预约时间
export const getHRBookHours = (params) => requests({
  url: `lawyerConsult/HRBookHours${params}`,
  method: 'get',
  // data
})
//律师提交预约
export const addBookHour = (data) => requests({
  url: `lawyerConsult/addBookHour`,
  method: 'post',
  data
})
//律师取消预约
export const delBookHour = (data) => requests({
  url: `lawyerConsult/cancelBookHour`,
  method: 'post',
  data
})
//确认完成审查
export const confirmExamine = (id) => requests({
  url: `lawyerConsult/Confirm/${id}`,
  method: 'post',
  // data
})
